"use strict";

function CookiePolicy(){

    var days = 365;

    var landingpageName = "";
    var fontColor = "";
    var backgroundColor = "";
    var buttonColor = "";
    var buttonFontColor = "";

    var acceptText = "";
    var legalText = "";

    this.init = function(lp, fc, bc, btc, bfc){
        
        landingpageName = lp;
        fontColor = fc;
        backgroundColor = bc;
        buttonColor = btc;
        buttonFontColor = bfc;

        var userLang = navigator.language || navigator.userLanguage; 



        acceptText = "Eu aceito";
        legalText = "Este site utiliza cookies para ajudar a disponibilizar os respectivos serviços, para personalizar os anúncios e analisar o tráfego. As informações sobre a sua utilização deste site são compartilhados com o Google. Ao utilizar esse site, você autoriza a utilização de cookies.";
        

        
    }

    this.generate = function (){

        var localCookie = readCookie(landingpageName);

        if(localCookie == "true"){

            return "";

        }else{

            var style = `
  
                    .containerPolicy{
                        position: fixed; 
                        width: 100%; 
                        left: 0px; 
                        bottom: 0px; 
                        display: block; 
                        background: `+backgroundColor+`; 
                        color: `+fontColor+`; 
                        font-family: arial, sans-serif;
                        z-index: 10000000000;
                    }

                    .contentPolicy {
                        padding: 20px 0;
                        width: 85%;
                        margin: auto; 
                        display: grid; 
                        align-items: center;
                        grid-template-columns: 5fr 1fr;
                    }

                    .paragraphPolicy
                    {
                        width: 90%;
                        display: inline-block;
                        font-size: 15px;
                        line-height: 24px;
                        color: `+fontColor+`;
                        margin-left: 5%;
                        margin-right: 5%;
                        text-align: left;
                    }

                    @media (max-width: 760px){

                        .contentPolicy {
                            grid-template-columns: 1fr;
                        }

                        .buttonAcceptPolicy{
                            margin-top: 10px;
                        }

                    }

                    .buttonAcceptPolicy{
                        background: `+buttonColor+`; 
                        border: none; 
                        color: `+buttonFontColor+`;
                        text-transform: uppercase;
                        font-size: 16px;
                        margin-top: 5px;
                        border-radius: 4px;
                        cursor: pointer;
                        display: block;
                        width: 100%;
                        height: 60px;
                        font-weight: bold;
                    }

                    .buttonAcceptPolicy:hover{
                        background: `+buttonFontColor+`; 
                        color: `+buttonColor+`;
                    }



            `;

            var styles = document.createElement('style');
            styles.innerHTML = style;
                
            var container = document.createElement('div');
            container.classList.add("containerPolicy");

            var content = document.createElement('div');
            content.id = "contentCookiePolicy";
            content.classList.add("contentPolicy");

            var paragraph = document.createElement('p');
            paragraph.classList.add("paragraphPolicy");
            paragraph.innerHTML = legalText;


            var button = document.createElement('button');
            button.textContent = acceptText;
            button.classList.add("buttonAcceptPolicy");
            button.onclick = function(){

                setCookie(landingpageName, true, days);
                container.style.display = "none";
                
            }

            container.appendChild(styles);
            container.appendChild(content);
            content.appendChild(paragraph);
            content.appendChild(button);

        }
        
        return container;
    }



    function setCookie(cookieName, cookieValue, nDays) {

        var today = new Date();
        var expire = new Date();

        if (nDays == null || nDays == 0) nDays = 1;

        expire.setTime(today.getTime() + 3600000 * 24 * nDays);
        document.cookie = cookieName + "=" + escape(cookieValue) + ";expires=" + expire.toGMTString() + "; path=/";
    }

    function readCookie(cookieName) {

        var theCookie = " " + document.cookie;
        var ind = theCookie.indexOf(" " + cookieName + "=");

        if (ind == -1) ind = theCookie.indexOf(";" + cookieName + "=");
        if (ind == -1 || cookieName == "") return "";
        var ind1 = theCookie.indexOf(";", ind + 1);
        if (ind1 == -1) ind1 = theCookie.length;

        return unescape(theCookie.substring(ind + cookieName.length + 2, ind1));
    }

}

var cookiePolicy = new CookiePolicy();
cookiePolicy.init("escolhaladysoft", "#fff", "#383433", "#ffdfd4", "#000");
var html = cookiePolicy.generate();
if(html != ""){ document.body.appendChild(html);}